@use "../src/styles/partials/colors.scss";
@use "../src/styles/partials/fonts.scss";

.App {
  padding: 1rem;
  text-align: center;
  height: calc(100vh - 2rem);
}

p, h1, h2, label {
  font-family: "Poppins", sans-serif;
}

body, #root {
  overflow-x: hidden;
  height: 100vh;
  background-color: colors.$OffWhite;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s ease-out forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.hidden {
  display: none;
}

.display {
  display: flex;
}