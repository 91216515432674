@use "../../styles/partials/colors.scss";
@use "../../styles/partials/mixins.scss";

@keyframes loading {
    0% {
      opacity: 0;
      top: 1rem;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }

.creative {
    position: relative;
    animation-name: loading;
    animation-duration: 1s;
    &__title {
        width: 80%;
        margin: 2rem auto;   
    }
    &__tile {
      cursor: pointer;
        @include mixins.tile;
    }
    &__container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    &__modal {
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100vw;
      height: 100vh;
      background-color: rgba(53,53,53,0.5);
      &--container {
        position: relative;
      }
      &--image {
        max-height: 80%;
      }
    }
}

@media (orientation: portrait) {
  .creative {
  &__modal {
    &--image {
      max-width: 80%;
    }
  }
}
}

@media (orientation: landscape) {
  .creative {
    &__container {
      max-width: 40rem;
      margin: auto;
    }
    &__modal {
      &--image {
        max-height: 80%;
      }
    }
  }
}