@use "../../styles/partials/mixins.scss";

@keyframes loading {
    0% {
      opacity: 0;
      top: 1rem;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }

.coding {
    position: relative;
    animation-name: loading;
    animation-duration: 1s;
    &__title {
        width: 80%;
        margin: 2rem auto;   
    }
    &__tile {
        @include mixins.tile;
    }
    &__container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}