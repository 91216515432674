@use "../../styles/partials/colors.scss";
@use "../../styles/partials/fonts.scss";

.contact--link {
  color: colors.$Black;
  transition: color 0.25s;
  &:hover {
    color: colors.$Magenta;
  }
}

.coding--link {
  color: colors.$Black;
  transition: color 0.25s;
  &:hover {
    color: colors.$Cyan;
  }
}

.creative--link {
  color: colors.$Black;
  transition: color 0.25s;
  &:hover {
    color: colors.$Yellow;
  }
}

.header {
  width: calc(100vw - 2rem);
  &__container {
    display: flex;
    justify-content: space-evenly;
    margin: 1rem auto;
  }
  &__link {
    color: colors.$Black;
    transition: color 0.5s;
    text-decoration: none;
    font-family: LemonMilk;
  }
}

@media (orientation: portrait) {
  .header {
    width: calc(100vw - 3rem);
    &__container {
      width: 80%;
    }
  }
}

@media (orientation: landscape) {
  .header {
    &__container {
      width: 50%;
    }
  }
}
